import "./import/modules";

// mmenu
document.addEventListener(
  "DOMContentLoaded", () => {
    new Mmenu("#mobile-nav", {
      extensions: [
        "theme-white",
        "border-none",
        // "fx-menu-slide",
        "shadow-page",
        "shadow-panels",
        "multiline", // переносим длинные названия
      ],

      navbar: {
        title: "Меню"
      },

      navbars: [
        {
          title: 'Меню'
        }
      ]


    }, {
      offCanvas: {
        pageSelector: "#page-wrapper"
      },
      classNames: {
        fixedElements: {
          fixed: "fix",
          sticky: "uk-sticky"
        }
      }
    });
  }
);